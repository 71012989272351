import React from 'react';

import { useLocation } from 'react-router-dom';

const Header = () => {
	
	const location = useLocation();

    return (
        <header id="header" className="header d-flex align-items-center">
			<div className="container-fluid container-xla d-flex align-items-center justify-content-between px-5">
				<a href="/" className="logo d-flex align-items-center">
					<img src="/assets/img/logo.png" alt="" />
				</a>

				<i className="mobile-nav-toggle mobile-nav-show nav-open bi bi-list"></i>
				<i className="mobile-nav-toggle mobile-nav-show d-none nav-close bi bi-x"></i>
				
				<nav id="navbar" className="navbar">
					<ul>
						<li><a href="/membership" className={location.pathname === '/membership' ? 'active' : ''}>Membership</a></li>
						<li className="dropdown"><a href="#" className={location.pathname.includes('/classes') ? 'active' : ''}><span>Classes</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
							<ul>
								<li><a href="/classes/yoga" className={location.pathname === '/classes/yoga' ? 'active' : ''}>YOGA</a></li>
								<li><a href="/classes/the_bar_studio" className={location.pathname === '/classes/the_bar_studio' ? 'active' : ''}>THE BAR STUDIO</a></li>
								<li><a href="#" className={location.pathname === '/classes/hit' ? 'active' : ''}>HIT</a></li>
								<li><a href="#" className={location.pathname === '/classes/pilates' ? 'active' : ''}>PILATES</a></li>
							</ul>
						</li>
						<li><a href="/personal_training" className={location.pathname === '/proyectos' ? 'active' : ''}>Personal Training</a></li>
						<li><a href="#" className={location.pathname === '/galeria' ? 'active' : ''}>Recovery</a></li>
						<li><a href="#" className={location.pathname === '/contacto' ? 'active' : ''}>My Account</a></li>
					</ul>
				</nav>

				<a href="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=5739475&tg=29&vt=&lvl=&stype=41&view=&trn=0&page=&catid=&prodid=&date=9%2f8%2f2024&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=1&loc=1" className="trial mobile-nav-hide d-flex align-items-center">
					<h4>1 WEEK FREE TRIAL</h4>
				</a>
			</div>
		</header>
    )
}

export default Header;