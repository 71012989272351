import React, { useEffect, Fragment } from 'react';

const Membership = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
			<main id="main">
                <section>
                    <div className="container-xxl container-portada d-flex justify-content-center align-items-center" style={{ backgroundImage: `url("/assets/img/headers/membership.jpg")` }} data-aos="fade-up">
                        <div className="text-center">
                            <h2 data-aos="fade-down">MEMBERSHIP</h2>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="terciario">Memberships at neighborhood gym</h2>
                        </div>

                        <div className="row mx-0 gy-4 justify-content-around">
                            <div className="col-md-10 contenedor-banner">
                                <p className="text-center">Explore our tailored plans desinged to fit your lifestyle and fitness goals. Choose the best plan for you and start your journey at Neighborhood Gym.</p>
                            </div>
                        </div>

                        <div className="row mx-2 my-5 container-membership container-membership-border justify-content-around">
                            <div className="col-lg-5 col-md-5 col-12 text-center container-membership-3">
                                <h5>$49.99/Month</h5>
                                <h6 className="mb-3">Open Gym Membership</h6>
                                <p>Unlimited Access to Open Gym equipment during regular operating hours.</p>
                                <a className="btn btn-image btn-lg btn-dark mt-5" href="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=5739475&tg=29&vt=&lvl=&stype=41&view=&trn=0&page=&catid=&prodid=&date=9%2f8%2f2024&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=1&loc=1">1 WEEK FREE TRIAL</a>
                            </div>

                            <div className="col-lg-5 col-md-5 col-12 text-center container-membership-3">
                                <h5>$99.99/month</h5>
                                <h6 className="mb-3">All-Access Membership</h6>
                                <p>Unlimited access to Open Gym equipment during regular operating hours.</p>
                                <p>Unlimited Group Fitness Classes.</p>
                                <a className="btn btn-image btn-lg btn-dark" href="https://clients.mindbodyonline.com/ASP/main_shop.asp?studioid=5739475&tg=29&vt=&lvl=&stype=41&view=&trn=0&page=&catid=&prodid=&date=9%2f8%2f2024&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=1&loc=1">1 WEEK FREE TRIAL</a>
                            </div>
                        </div>

                        <div className="row mx-0 my-5 gy-4 justify-content-around">
                            <div className="col-lg-8 img-bg-2" style={{ backgroundImage: `url("/assets/img/membership/memberships.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="offset-md-2 col-md-8 contenedor-banner text-center">
                                <p className="text-center">Each membership starts with a complimentary fitness consultation to help you identify and begin working towards your personal fitness goals.</p>
                                <span className="text-center">For more information, please email us at: <a href="mailto:info@neighborhood-gym.com">info@neighborhood-gym.com</a>.</span>
                            </div>

                            <div className="offset-md-2 col-md-8 text-center">
                                <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">Get Started Today</a>
                            </div>
                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default Membership;