import React, { useEffect, Fragment } from 'react';

const PersonalTraining = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
			<main id="main">
                <section>
                    <div className="container-xxl container-portada d-flex justify-content-center align-items-center" style={{ backgroundImage: `url("/assets/img/headers/personal_training.jpg")` }} data-aos="fade-up">
                        <div className="text-center">
                            <h2 data-aos="fade-down">PERSONAL TRAINING</h2>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">Personal Training at Neighborhood Gym</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Unlock your full potential with personalized 1-hour training sessions at Neighborhood Gym. Our expert trainers are here to guide you through tailored workouts that focus on your specific goals, whether you´re aiming tod build strength, lose weight, enhance your endurance or improve overall fitness.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="get-started services section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="terciario text-center">Why choose personal training at Neighborhood Gym?</h2>
                        </div>

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-5 img-bg-2" style={{ backgroundImage: `url("/assets/img/personal_training/training.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>

                            <div className="col-lg-6 d-flex flex-column justify-content-center">
                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Customized Workouts</h4>
                                        <p className="img-description">Every session is dessigned to meet your unique fitness needs and preferences.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Expert Guidance</h4>
                                        <p className="img-description">Our certified personal-trainers provide the expertise and motivation to help you maximize your results.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div className="container-description">
                                        <h4 className="img-subtitle">Flexible Scheduling</h4>
                                        <p className="img-description">Book sessions at times that fit your busy lifestyle through our easy-to-use Mindbody scheduling system.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="terciario text-center">Get started with personal training</h2>
                        </div>

                        <div className="row mx-0 gy-4 container-training-gray">
                            <div className="col-lg-4 col-md-4 col-12 text-center mt-0 container-training-1">
                                <i class="fa-solid fa-dumbbell"></i>
                                <h5>Consultation</h5>
                                <p>Start with a free consultation to discuss your goals and assess your fintness level.</p>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 text-center mt-0 container-training-2">
                                <i class="fa-solid fa-sliders"></i>
                                <h5>Tailored Plan</h5>
                                <p>Receive a customized workout plan to deliver efficient and effective results.</p>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 text-center mt-0 container-training-3">
                                <i class="fa-solid fa-heart-circle-check"></i>
                                <h5>Ongoing Support</h5>
                                <p>Benefit from continuous guidance and adjustments to your programa as your progress.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">Wheter you´re new to the gym or and experienced athlete, our trainers are ready to help you grow.</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="offset-md-2 col-md-8 contenedor-banner">
                                <p className="text-center">Book your Personal Trianing session today and take the first step towards at fitter, healthier you!</p>
                            </div>

                            <div className="offset-md-2 col-md-8 text-center">
                                <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">Get Started Today</a>
                            </div>
                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default PersonalTraining;