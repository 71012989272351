import React, { useEffect, Fragment } from 'react';

const TheBarStudio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
			<main id="main">
                <section>
                    <div className="container-xxl container-portada d-flex justify-content-center align-items-center" style={{ backgroundImage: `url("/assets/img/headers/the_bar_studio.jpg")` }} data-aos="fade-up">
                        <div className="text-center">
                            <h2 data-aos="fade-down">THE BAR STUDIO</h2>
                            <p data-aos="fade-up">Balance and Strength.</p>
                            <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="#">Book Now</a>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">TBH Method at Neighborhood Gym</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Welcome to TBS is a unique space where we turn your workout into a magical experience. We offer classes that combine barre, sculpt, hit & fok, designed to improve core, tone and strength, enhancing flexibility and boosting overall muscle tone.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="terciario text-center">Class Experience</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">We believe in a canored and joyful ide throught movement. We guide you in building the core of who you are growing from within. Through porpuseful movement and deep connections -with yourself, your body and mind, and support community- find a space where balance and joy thrive, and your true self is truly valued.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="get-started services">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-5 img-bg" style={{ backgroundImage: `url("/assets/img/the_bar_studio/team.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>

                            <div className="col-lg-6 d-flex flex-column justify-content-center">
                                <h2 className="quinto mb-4">Why Barre?</h2>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Precision and Control</h4>
                                        <p className="img-description">Barre´s small, controlled movements make a big impact on muscle definition and strength.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Enhanced Flexibility and Balance</h4>
                                        <p className="img-description">Improve your range of motion and stabilize your core, enhancing both flexibility and balance.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div className="container-description">
                                        <h4 className="img-subtitle">Full-Body Workout</h4>
                                        <p className="img-description">Target multiple muscle group with exercises that one as challenging as they are rewarding.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-12 d-flex flex-column">
                                <div className="section-header">
                                    <h2 className="terciario">Schedule</h2>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <p className="img-description">Please arrive 10 minutes early and check in at our front desk.</p>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-week icono-temporal"></i>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">JOIN US</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="offset-md-2 col-md-8 contenedor-banner">
                                <p className="text-center">Wheter you´re looking to refine your physique, improve posture, or simply switch up your routine. Barre at Neighborhood Gym offers a unique workout experience that ties in beautifully with our communityfocused fitness philosophy. Sign up for a class today and feel the difference a Barre workoout can make!</p>
                            </div>

                            <div className="offset-md-2 col-md-8 text-center">
                                <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">Get Started Today</a>
                            </div>
                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default TheBarStudio;