import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';

const Inicio = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
            <section id="hero" className="hero">
                <div className="info d-flex align-items-center">
                    <div className="container-xxl">
                        <div className="row mx-0 justify-content-center">
                            <div id="titulo_texto" className="col-lg-8 text-center">
                                <h2 data-aos="fade-down">PACIFIC BEACH GYM</h2>
                                <p data-aos="fade-up">Pacific Beach's Best Indoor/Outdoor Gym, with Functional Equipment, Classes and Turf areas.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
                    <div className="carousel-item active" style={{ backgroundImage: `url("/assets/img/headers/home.jpg")` }}></div>

                    {/* <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                    </a>

                    <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                    </a> */}
                </div>
            </section>

			<main id="main">
                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="principal text-center">Welcome to Neighborhood Gym</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">At Neighborhood Gym, we’re more than just a place to work out—we’re your community fitness hub, offering a range of options to fit every fitness level and goal. Join us in achieving your best self in a supportive and motivating environment.</p>
                            </div>

                            <div className="col-md-12 text-center">
                                <a className="btn btn-general btn-lg btn-light mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">Start Now</a>
                                <a className="btn btn-general btn-lg btn-light mx-3 my-2" href="/personal_training">Personal Trainer</a>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="secundario">Amenities</h2>
                        </div>

                        <div className="row mx-0 gy-2">
                            <div className="col-lg-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/yoga.jpg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">Yoga</h3>
                                        <a className="btn btn-image btn-lg btn-dark" href="/classes/yoga">Book Now</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/cardio.jpg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">Cardio</h3>
                                        <a className="btn btn-image btn-lg btn-dark" href="#">Book Now</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/the_bar_studio.jpg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">The Bar Studio</h3>
                                        <a className="btn btn-image btn-lg btn-dark" href="/classes/the_bar_studio">Book Now</a>
                                    </div>
                                </div>
                            </div>

                            <div className="offset-lg-2 offset-md-2 col-lg-4 col-md-4 col-6 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/free_weights.jpg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">Free Weights</h3>
                                        <a className="btn btn-image btn-lg btn-dark" href="#">Book Now</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12 container-amenities" data-aos="fade-up" data-aos-delay="100">
                                <div className="img-amenities" style={{ backgroundImage: `url("/assets/img/home/hit.jpeg")` }}>
                                    <div className="content-amenities">
                                        <h3 className="text-center">Hit</h3>
                                        <a className="btn btn-image btn-lg btn-dark" href="#">Book Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="get-started services">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 gy-2">

                            <div className="col-lg-3 col-md-4 col-12" data-aos="fade-up" data-aos-delay="100">
                                <div className="section-header membership-header-container">
                                    <h2 className="terciario">Membership Plans</h2>
                                    <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">GET 1 WEEK PASS</a>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-8 col-12" data-aos="fade-up" data-aos-delay="100">
                                <div className="row container-membership container-membership-red mx-2">
                                    <div className="col-lg-6 col-md-6 col-12 text-center container-membership-1">
                                        <h5>$49.99/Month</h5>
                                        <h6>Open Gym Membership</h6>
                                        <p>Unlimited Access to Open Gym equipment during regular operating hours.</p>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12 text-center container-membership-2">
                                        <h5>$99.99/month</h5>
                                        <h6>All-Access Membership</h6>
                                        <p>Unlimited access to Open Gym equipment during regular operating hours.</p>
                                        <p>Unlimited Group Fitness Classes.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row mx-0 mt-5 pt-5 justify-content-around gy-2">
                            <div className="col-lg-5 img-bg" style={{ backgroundImage: `url("/assets/img/home/neighborhood_gym.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>

                            <div className="col-lg-6 d-flex flex-column justify-content-center">
                                <h3 className="img-title mb-4">What's at Neighborhood Gym?</h3>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Open Gym</h4>
                                        <p className="img-description">Take advantage of our state-of-the-art equipment during normal operating hours. Whether you’re into cardio, strength training, or just general fitness, our open gym is equipped with everything you need to succeed.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div>
                                        <h4 className="img-subtitle">Group Fitness Classes</h4>
                                        <p className="img-description">Join our diverse and dynamic classes designed for every fitness level. From high-intensity Strength/HIIT sessions to restorative Pilates and Lagree classes, our expert instructors will guide you through safe and effective workouts that challenge and inspire.</p>
                                    </div>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <div className="container-description">
                                        <h4 className="img-subtitle">Personal Training</h4>
                                        <p className="img-description">Looking to take your fitness to the next level? Our certified personal trainers are here to help you achieve your goals with personalized one-on-one sessions. Each session is tailored to your specific needs, ensuring you get the most out of every workout.</p>
                                        <a className="btn btn-general btn-lg btn-light mt-3" href="#">See All Benefits</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-6 d-flex flex-column">
                                <div className="section-header">
                                    <h2 className="terciario">Class Schedule</h2>
                                </div>

                                <div className="mb-2 d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                                    <p className="img-description">Please arrive 10 minutes early and check in at our front desk.</p>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-week icono-temporal"></i>
                                </div>

                            </div>

                            <div className="col-lg-5 img-bg" style={{ backgroundImage: `url("/assets/img/home/class_schedule.jpg")` }} data-aos="zoom-in" data-aos-delay="100"></div>
                        </div>

                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">Try Us Out</h2>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Start your journey with us today with a **<b className="parrafo-description">1 Free Day Pass</b>**—a perfect way to explore everything we have to offer. Whether you’re trying out the latest equipment, joining a group class, or working one-on-one with a trainer, we’re here to support your fitness goals.</p>
                            </div>

                            <div className="col-md-12 text-center">
                                <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">GET 1 WEEK PASS</a>
                            </div>

                            <div className="col-md-12 text-center">
                                <b className="parrafo-complete">875 Garnet Ave, San Diego, CA 92109</b>
                            </div>
                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default Inicio;