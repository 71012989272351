import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Inicio from './views/Inicio';
import Membership from './views/Membership';
import TheBarStudio from './views/Classes/TheBarStudio';
import Yoga from './views/Classes/Yoga';
import PersonalTraining from './views/PersonalTraining';

import Alert from './components/layout/Alert';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Extras from './components/layout/Extras';
import NotFoundPage from './components/routing/NotFoundPage';

//Redux
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Header></Header>
					<Alert></Alert>
					<Routes>
						<Route exact path="/" element={<Inicio />} />
						<Route exact path="/membership" element={<Membership />} />
						<Route exact path="/classes/the_bar_studio" element={<TheBarStudio />} />
						<Route exact path="/classes/yoga" element={<Yoga />} />
						<Route exact path="/personal_training" element={<PersonalTraining />} />

						{/* Cuando no encuentra nada*/}
						<Route path="*" element={<NotFoundPage />}/>
					</Routes>
					<Footer></Footer>
					<Extras></Extras>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
