import React, { Fragment } from 'react';

const Extras = () => {
    return (
        <Fragment>
            <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
            <div id="preloader"></div>
        </Fragment>
    )
}

export default Extras;