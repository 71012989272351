import React, { useEffect, Fragment } from 'react';

const Yoga = () => {    

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
    	<Fragment>
			<main id="main">
                <section>
                    <div className="container-xxl container-portada d-flex justify-content-center align-items-center" style={{ backgroundImage: `url("/assets/img/headers/yoga.jpg")` }} data-aos="fade-up">
                        <div className="text-center">
                            <h2 data-aos="fade-down">Yoga</h2>
                            <p data-aos="fade-up">Group Fitness.</p>
                            <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="#">Book Now</a>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="section-header">
                            <h2 className="cuarto text-center">Elevate Your Fitness with Yoga Classes at Neighborhood Gym</h2>
                        </div>

                        <div className="row mx-0 my-3">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Discover the transformative power of Yoga at Neighborhood Gym with our specialized group classes designed to cater to various fitness and experience levels. Join us for our two dynamic class styles.</p>
                            </div>
                        </div>

                        <div className="row mx-0 my-3 justify-content-around">
                            <div className="col-md-5 mb-3 contenedor-subbanner text-center p-3">
                                <h2 className="quinto mb-4">Yoga Sculpt</h2>
                                <p className="text-center">Elevate your yoga routine with Yoga Sculpt. This invigorating class combines Vinyasa-style yoga with weight training and cardio to sculpt and tone your body while improving flexibility and balance. Set tp upbeat music, each session promisses a challenging workout that energizes bot body and mind.</p>
                            </div>

                            <div className="col-md-5 mb-3 contenedor-subbanner text-center p-3">
                                <h2 className="quinto mb-4">Power Flow Vinyasa</h2>
                                <p className="text-center">Experience the essence of flow with our Power Flow Vynasa classes. This dynamic and challenging style of yoga emphasizes strength, flexibility and endurance. Through seamless transitions and powerful poses, you´ll connect breath with movement, cultivating a balanced and invigorating practice surted of yoga looking to deepen their practice.</p>
                            </div>
                        </div>

                        <div className="row mx-0 mt-5">
                            <div className="col-md-12 contenedor-banner">
                                <p className="text-center">Our classes are designed to accomodate everyone from beginners to advanced yoga, ensuring a welboming and supportive environment. Join us at Neighborhood Gym and transform your practice with our expert instructors who are helping you achieve your welness goals.</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="section-bg">
                    <div className="container-xxl" data-aos="fade-up">

                        <div className="row mx-0 justify-content-around gy-2">
                            <div className="col-lg-12 d-flex flex-column">
                                <div className="section-header">
                                    <h2 className="terciario">Schedule</h2>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <p className="img-description">New Customers: Book below and purchase your 1 FREE VISIT at checkout for $0.</p>
                                </div>

                                <div className="mb-2 text-center" data-aos="fade-up" data-aos-delay="100">
                                    <i className="bi bi-calendar-week icono-temporal"></i>
                                </div>

                            </div>
                        </div>

                        <div className="row mx-0 gy-4">
                            <div className="offset-md-2 col-md-8 contenedor-banner">
                                <p className="text-center">Visit us and explore how our Neighborhood Yoga classes can enhance your fitness journey and bring harmony to your mind and body.</p>
                            </div>

                            <div className="offset-md-2 col-md-8 text-center">
                                <a className="btn btn-general btn-lg btn-dark mx-3 my-2" href="https://clients.mindbodyonline.com/classic/ws?studioid=5739475&stype=41&sTG=29&prodId=100024this">Get Started Today</a>
                            </div>
                        </div>

                    </div>
                </section>
            </main>            
	    </Fragment>
    )
}

export default Yoga;